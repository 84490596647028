import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../components/Navbar'
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import { AiOutlineSearch } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';


// Declare Instascan as a global variable
/* global Instascan */

const QRCodeScanner = () => {
  const scannerRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [scanText, setScanText] = useState('')
  const [modalIsOpen, setIsOpen] = useState(false);
  const [studentInfo, setStudentInfo] = useState({})
  const [initialRender, setInitialRender] = useState(true);
  //const [verified, setVerified] = useState(false)
  //const [batch, setBatch] = useState('')
  //const [course, setCourse] = useState('')
  const [roll, setRoll] = useState('');

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // Example: Get a specific query param
  const exam = queryParams.get('exam');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleRollChange = event => {
    setRoll(event.target.value)
    console.log(roll);
  }

  const branch = JSON.parse(localStorage.getItem('branch'));

  const searchByRoll = async () => {
    try {
      const response = await fetch(`https://api.manager.achieve.apars.shop/roll/?roll=${roll}`);
      const jsonData = await response.json();
      toast(jsonData.message)
      if (jsonData.status === 200 && jsonData.data.offline === true) {
        setStudentInfo(jsonData.data);
        openModal()
      }
      else {
        toast.error(jsonData.message)
      }

    } catch (error) {
      toast.error('Error fetching data:', error)
    }
    finally {
      setLoading(false);
    }
  };

  const countAttendence = async () => {
    try {
      const response = await fetch('https://branch-manager-backend.onrender.com/attendance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: studentInfo.uid || studentInfo._id,
          exam_id: exam,
          address: studentInfo.Address,
          email: studentInfo.Email,
          FbLink: studentInfo.FbLink,
          FbName: studentInfo.FbName,
          HSC: studentInfo.HSC,
          Institution: studentInfo.Institution,
          Name: studentInfo.Name,
          Parent: studentInfo.Parent,
          Phone: studentInfo.Phone,
          roll: studentInfo.roll,
          batch: studentInfo.Courses[0].course,
          branch: branch
        })
      });
      const jsonData = await response.json();
      toast(jsonData.message)
      closeModal()
      setScanText('')
      setStudentInfo({})
     // setVerified(false)
    } catch (error) {
      toast.error('Error fetching data');
    }
  };

  useEffect(() => {
    let cleanupFunction = () => { };

    const setupScanner = async () => {
      const video = document.getElementById('video');
      const opts = {
        continuous: true,
        video: video,
        mirror: false,
        captureImage: false,
        backgroundScan: true,
        refractoryPeriod: 5000,
        scanPeriod: 1
      };

      scannerRef.current = new Instascan.Scanner(opts);

      try {
        const cameras = await Instascan.Camera.getCameras();
        if (cameras.length > 0) {
          let selectedCamera = cameras.find(camera => camera.name.toLowerCase().includes('back'));
          if (!selectedCamera) {
            console.log('No back camera found. Defaulting to the first camera.');
            selectedCamera = cameras[0];
          }
          scannerRef.current.start(selectedCamera);
        } else {
          console.error('No cameras found.');
          toast.error('No cameras found.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Error accessing cameras.');
      }
    };

    setupScanner();

    const handleScan = (content) => {
      const protocol = 'ACHIEVE://';

      if (content.startsWith(protocol)) {
        console.log('Scanned: ' + content);
        setScanText(content.split(protocol)[1])
      }
      else {
        toast.error("Invalid qr code!")
      }
    };

    scannerRef.current?.addListener('scan', handleScan);

    cleanupFunction = () => {
      scannerRef.current?.removeListener('scan', handleScan);
      scannerRef.current?.stop();
    };

    // Cleanup function
    return cleanupFunction;
  }, []); // Empty dependency array ensures this effect runs only once during mounting

  useEffect(() => {
    if (!initialRender && scanText !== '') {
      const fetchData = async () => {
        try {
          setLoading(true)
          const response = await fetch('https://api.manager.achieve.apars.shop/scan', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: scanText })
          });
          const jsonData = await response.json();
          console.log(jsonData);
          if (jsonData.status === 200 && jsonData.data.offline === true) {
            setStudentInfo(jsonData.data);
            console.log(studentInfo);
            openModal()
          }
          else {
            toast.error(jsonData.message)
            setScanText('')
          }



          
        } catch (error) {
          toast.error('Error fetching data:', error)
        }
        finally {
          setLoading(false);
        }
      };
      fetchData();
    }
    // Set initialRender to false after the first render
    if (initialRender) {
      setInitialRender(false);
    }
    // Call fetchData when stateVariable changes
  }, [scanText, initialRender]); // Dependency array

  return (
    <>
      <Navbar></Navbar>
      <ToastContainer position="top-center" autoClose={1500} limit={1} />
      <br />
      <br />
      <div className='px-4 md:px-24 flex gap-6'>
        <div className="flex items-center border border-gray-500 rounded-lg px-3 py-2">
      <AiOutlineSearch size={20} />
      <input
        type="text"
        placeholder="Search" onChange={handleRollChange}
        className="flex-grow ml-2 outline-none placeholder-gray-500"
      />
    </div>
    <button className='bg-blue-400 py-2 px-10 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={searchByRoll}>Search</button>
      </div>

      <div className='flex justify-center items-center mt-10'>
        <video id="video" autoPlay ></video>
      </div>
      <p className='text-center'> {scanText} </p>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <p>Branch: {studentInfo && studentInfo.Branch}</p>
          {
            studentInfo.Courses && studentInfo.Courses.map(course => {
                if(course.course === "AchieveEngineering24" || course.course === "AchieveVarsity24" || course.course === "AchieveMedical24" || course.course === "AchieveAgriculture24") {
                  return (
                    <div>
                      <p>Course: {course.course}</p>
                      <p>Batch Time: {course.batch}</p>
                    </div>
                  
                  )
                }
            })
          }
          
          {studentInfo.photo && <img src={studentInfo.photo} alt="Student" className='w-20 h-20'/>}
          <button onClick={() => countAttendence()} className='bg-blue-400 p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110'>Give attendence</button>
        </div>
      </Modal>
      <Modal isOpen={loading}
        style={customStyles}
        contentLabel="Example Modal">
        <FadeLoader color="#4292cf" />
      </Modal>
    </>
  );
};

export default QRCodeScanner;
